<template>
  <div>
    <div v-if="isShow == 0">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户资料</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload">
          <i style="font-size: 18px" class="el-icon-refresh-right"></i>
        </el-button>
      </div>

      <el-row style="padding: 20px 20px 0; white-space: nowrap">
        <el-col :span="6">
          <span>日期范围：</span>
          <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="2">
          <el-select v-model="option1" clearable filterable placeholder="用户状态">
            <el-option v-for="item in option1es" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-input v-model="QueryContent" placeholder="手机号、姓名、昵称" style="width: 70%; margin-right: 5px"></el-input>
          <el-button type="success" size="mini" style="margin-left: 5px" @click="serachClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini" style="margin-left: 5px" @click="exportClick">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
              <span style="margin-left: 3px">导出</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
      <div style="padding: 20px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border style="width: 100%">
          <el-table-column prop="create_time" sortable label="注册日期"></el-table-column>
          <el-table-column prop="nick_name" label="昵称"></el-table-column>
          <el-table-column prop="member_name" label="名字"></el-table-column>
          <el-table-column prop="bind_phone" label="绑定手机"></el-table-column>
          <el-table-column prop="wallet_balance" sortable label="钱包余额"></el-table-column>
          <el-table-column prop="login_end_time" sortable label="最近登录时间"></el-table-column>
          <el-table-column prop="purchase_date" sortable label="最后购买日期"></el-table-column>
          <el-table-column prop="line_state" label="在线状态">
            <template slot-scope="scope">
              {{ scope.row.line_state == 0 ? "离线" : "在线" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" @click="handleAssigned(scope.row)">过户</el-button>
              <el-button type="primary" @click="handleDetails(scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style="">显示第&nbsp;{{
            tableData.length == 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
  (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

          <div style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            ">
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination background layout=" pager,slot" :total="tableFinshNum" :current-page="page"
              @current-change="currentChange"></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <assigned v-if="isShow == 1" @close="onClose" :assigned_message="assignedMsg"></assigned>
    <UpDateUser v-if="isShow == 2" @close="onClose" :data="assignedMsg" />
  </div>
</template>
<script>
import assigned from "./childrens/assigned.vue";
import detail from "./childrens/details.vue";
import { getTime } from "../../../../until/getTime";
import UpDateUser from "../label3_memberInformation/childrens/memberEdit.vue";
import { xlixs } from "@/until/xlsx";
export default {
  components: {
    assigned,
    detail,
    UpDateUser,
  },
  data() {
    return {
      date: "",

      option1: "",
      option1es: [
        { label: "15天没有交易", value: 15 },
        { label: "20天没有交易", value: 20 },
        { label: "30天没有交易", value: 30 },
        { label: "40天没有交易", value: 40 },
        { label: "50天没有交易", value: 50 },
        { label: "60天没有交易", value: 60 },
      ],
      QueryContent: "",

      tableData: [],
      tableFinshNum: 0,
      isShow: 0,
      assignedMsg: {},
      page: 1,
      length: 10,
    };
  },
  mounted() {
    this.getTabList();
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
    onClose() {
      this.isShow = 0;
      this.getTabList();
      this.assignedMsg = "";
    },
    getTabList() {
      this.$http
        .post("/client/user/lst", {
          currentPage: this.page,
          currentLength: this.length,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleAssigned(val) {
      this.isShow = 1;
      this.assignedMsg = val;
    },
    handleDetails(row) {
      console.log(row);
      this.assignedMsg = row;
      this.isShow = 2;
    },
    serachClick() {
      this.$http
        .post("/client/user/search", {
          firstTime: this.date[0] && getTime(this.date[0]),
          lastTime: this.date[1] && getTime(this.date[1]),
          search: this.QueryContent,
          status: this.option1,
          currentPage: this.page,
          currentLength: this.length,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
    exportClick() {
      this.$confirm("每次导出最大商品数为10000条？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("/client/user/export");
          let arr = res.data.data;
          arr = arr.map((item) => {
            return Object.values(item);
          });
          xlixs(
            arr,
            ["姓名", "电话", "注册日期", "最后购买时间", "地址(默认地址)"],
            "data"
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}

/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
